import React, {
  useEffect,
  useRef,
  useState
} from 'react'
import './HomePage.css'
import { useNavigate } from 'react-router-dom';
import styles from '../Navbar/NavStyles'
import axios from 'axios'
import { BASE_URL, decData, encData, post, postUser } from '../Services/Calls'
import { Base64 } from 'js-base64'
import Tooltip from '@mui/material/Tooltip';
import MenuIcon from '../../assets/images/menu.png'
import Magnifier from '../../assets/images/magnifier.png'
import LogOutIcon from '../../assets/images/logOut.png'
import CloseMenuListIcon from '../../assets/images/closeMenuListIcon.png'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Snackbar from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';
import Slide, { SlideProps } from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

function LandingPage () {
  const navigate = useNavigate();
  
  const [allUser, setAllUser]: any = useState([])
  const [allUserFilter, setAllUserFilter]: any = useState([])
  const [userData, setuserData]: any = useState({})
  const [firstName, setFirstName]: any = useState()
  const [lastName, setLastName]: any = useState()
  const [email, setEmail]: any = useState()
  const [nameLetters, setNameLetters]: any = useState()
  const [userId, setUserId] = useState('')
  const [menuOpen, setMenuOpen] = useState(false)
  const [deleteUserLoader, setDeleteUserLoader] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState("")

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('user') || '{}')
    setuserData(user)
    try {
      setFirstName(Base64.decode(user.firstName))
      setLastName(Base64.decode(user.lastName))
      setEmail(Base64.decode(user.email))
      if(user.firstName !="")
      {
      setNameLetters(
        Base64.decode(user.firstName).charAt(0) +
          Base64.decode(user.lastName).charAt(0)
      )
      }
      else
      {
        setNameLetters(
          Base64.decode(user.email).charAt(0) 
        )
      }
    } catch (ex) {
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setEmail(user.email)
      setNameLetters(user.firstName.charAt(0) + user.lastName.charAt(0))
    }
    let userId = user.id
    console.log('user12', user)
    setUserId(userId)
    getUser(userId)
    getvisitercount()
  }, [])

  function LogOutFun () {
    var myCookie = localStorage.getItem("setCookie");
    localStorage.clear();
    if (myCookie != null) {
      localStorage.setItem("setCookie", myCookie);
    }
    // navigate('/')
     window.location.reload()
  }


  const getUser = async userId => {
    console.log('taskId2311', userId)
    let config = {
      userid: userId
    }
    try {
      const apiData = await post(BASE_URL + '/getusersbyadmin', config)
      console.log('apiData45567', apiData)
      if (apiData.data.status == 'success') {
        console.log('apiData.data.data', apiData.data.data)
        // setAllUser(apiData.data.data)
        let data = apiData.data.data
          for(let i=0;i<data.length;i++){
            data[i].email = Base64.decode(data[i].email)
            data[i].firstName = Base64.decode(data[i].firstName)
            data[i].lastName = Base64.decode(data[i].lastName)
          }
          setAllUser(data)
          setAllUserFilter(data)
          console.log('newData12',allUser)
          console.log('newData123',data)
        }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  const deleteUser = async (id) => {
    setDeleteUserLoader(true)
    let config = {
      deleteUserId: id
    }
    try {
      const apiData = await post(BASE_URL + '/deleteuseraccount', config)
      console.log('apiData45567', apiData)
      if (apiData.data.status == 'success') {
        getUser(userId)
        setDeleteUserLoader(false)
      }
      else{
        setDeleteUserLoader(false)
      }
    } catch (ex) {
      console.log('response9211', ex)
      setDeleteUserLoader(false)
    }
  }


  async function verifyRegisterLink(user) {
    setSnackbarMsg("Resend welcome email successfully!")
    try {
      const data = {
        userid:user.id,
        email:Base64.encode(user.email)
      };
      console.log('data',data)
      handleClickSnackBar(SlideTransition)
      const response = await post(BASE_URL + '/sendemailconfirmationlink', data)
      console.log('response2345',response)
        if(response && response.data && response.data.data && response.data.data.length>0){
      }
      else{

      }
    } catch (ex) {
      console.log('response',ex)
    }
  };

  async function resendPasswordLink(user) {
    setSnackbarMsg("Send password reset email successfully!")
    try {
      const data = {
        email:Base64.encode(user.email)
      };
      console.log('data',data)
      handleClickSnackBar(SlideTransition)
      const response = await post(BASE_URL + '/sendresetpasswordlinkemail', data)
      console.log('response2345',response)
      if(response.data.status == 'success'){
      }
      else{

      }
    } catch (ex) {
      console.log('response',ex)
    }
  };


    function menuToggle () {
    if (menuOpen) {
      setMenuOpen(false)
    } else {
      setMenuOpen(true)
    }
  }

  const userDeleteItemRef: any = useRef({})

  const handleDeleteConfirm = (userForDetele: any) => {
    userDeleteItemRef.current = userForDetele
    confirmAlert({ customUI: addDialog })
  }

  const addDialog = ({ onClose }) => {
    const handleClickedNo = () => {
      onClose()
    }
    const handleClickedYes = async () => {
      await deleteUser(userDeleteItemRef.current.id)
      onClose()
    }
    return (
      <div className='add-dialog'>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center', marginBottom: 20}}>             
        <h5 style={{ fontWeight: 700, color: '#000', fontSize:17, marginBottom: 0 }}>
        Delete Account
        </h5>
        <img style={{width:12, cursor:'pointer'}} src={CloseMenuListIcon} alt='' onClick={handleClickedNo} />
        </div>
        <div >
        Are you sure you want to delete <span style={{fontSize:14,color:'rgb(25,25,25)',fontWeight:'800'}}>"{userDeleteItemRef.current.email}"</span> account?
        </div>
        <div className='deleteUserBtn' style={{display: 'flex', marginTop: 40,justifyContent:'flex-end',gap:0 }}>
        <button
            style={{
              background: '#fff',
              color: '#666',
              border: '0.1px solid rgba(0, 0, 0, 0.3)',
              // width: 105
            }}
            onClick={handleClickedNo}
          >
            Cancle
          </button>
          {deleteUserLoader ?
          <button
            style={{ background: 'rgba(71, 114, 250,0.8)', color: '#fff' }}
          >
            Delete...
          </button>
          :
          <button
            style={{ background: 'rgb(71,114,250)', color: '#fff' }}
            onClick={handleClickedYes}
          >
            Delete
          </button>
  }
         
        </div>
      </div>
    )
  }

  function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
  }

  function handleClickSnackBar(Transition: React.ComponentType<TransitionProps & {children: React.ReactElement<any, any>;}>, ){
    setOpenSnackBar({
        open: true,
        Transition,
      });
    };

  const [openSnackBar, setOpenSnackBar] = React.useState<{ open: boolean; Transition: React.ComponentType<TransitionProps & {children: React.ReactElement<any, any>; }>;}>({
    open: false,
    Transition: Fade,
  });

  function handleCloseSnackBar(){
    setOpenSnackBar({...openSnackBar, open: false, });
  };
  const handleChangeShareInput = (event: {target: { value: React.SetStateAction<string> }}) => {
    console.log('e.target.value11', event.target.value)
    console.log('e.target.value.length11', event.target.value.length)
    /* if(event.target.value != ''){
    setUserListDivShow(true)
  }
  else{
    setUserListDivShow(false)
  } */
    
    let data: any = [...allUser]
    let newData :any = []
    let eventValue:any = event.target.value
    if (event.target.value !== '') {
      for(let i=0;i<data.lengthl;i++){
        data[i].email = Base64.decode(data[i].email)
      }
      newData.push(data)
      console.log('newData12',newData)
      console.log('newData123',data)
      const filteredData: any = data.filter(item => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(eventValue.toLowerCase())
      })
      console.log('filteredData123', filteredData)
      setAllUserFilter(filteredData)
    } else {
      setAllUserFilter(data)
    }
  }


  const [visiterCount, setVisiterCount]: any = useState(0);

  async function getvisitercount() {
    try {
      const response = await post(BASE_URL + '/getvisitercount',{})
      console.log('response23',response)
      if(response && response.data && response.data.data && response.data.data.length>0){
        setVisiterCount(response.data.data[0].counts)
      }
    } catch (ex) {
      console.log('response',ex)
  };
  }




  return (
    <div style={{display:'flex'}}>

<Snackbar
      anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
        open={openSnackBar.open}
        onClose={handleCloseSnackBar}
        TransitionComponent={openSnackBar.Transition}
        message={snackbarMsg}
        key={openSnackBar.Transition.name}
        autoHideDuration={3000}
        className='trashSnackBar'
      />

      {/* {menuOpen &&
      <div style={{ minWidth: 280, maxWidth: 280, height: '100%' }}>
            <div className='mainMenuDiv'>
                <div style={{ paddingLeft: 8 }}>
                  <div className='manageStuffHeading'>Admin Manage Stuff</div>
                  <div className='MainProfileDiv'>
                    <div style={{ width: '15%' }}>
                      <div className='userAvtarDiv'>
                        {nameLetters}
                      </div>
                    </div>
                    <div className='userDetailDiv'>
                      <div
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          color: '#464645'
                        }}
                      >
                        {firstName} {lastName}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ fontSize: 12 }}>{email}</div>
                        <div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
          </div>
          </div>
          } */}
       <div style={{width: '100%',background: '#6478c7',zIndex: 1,height: '100vh',}}>
       <div className='MainUpperDiv'
                  style={{
                    position: 'absolute',
                    background: 'rgb(100, 120, 199,0.6)',
                    // width:'calc(100% - 290px)',
                    width:'100%',
                    top: 0,
                    backdropFilter: 'blur(3.5px)',
                    zIndex: 1,
                    borderTopLeftRadius: 12
                  }}
                >
                  <div className='titleHeadMenu'>
                    {/* <img
                style={{ width: 22, cursor: 'pointer', paddingTop: 5 }}
                onClick={() => menuToggle()}
                src={MenuIcon}
              /> */}
                          <div style={{
                              fontWeight: 600,
                              color: '#fff',
                              fontSize: '1.6rem',
                              letterSpacing:'1px',
                            }}
                          >
                            <span>
                             User's Account
                            </span>
                          </div>
                  </div>
                  <div style={{
                    display:'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 25,
                    marginRight:10
                  }}>
                    {visiterCount && visiterCount != 0 &&
<div style={{border:"0.1px solid lightgray",padding: '10px',borderRadius: '8px',textAlign:'center',fontWeight:600,color:'#fff'}}>
  Visiters <br /> {visiterCount}
</div>
}
                  <div className='MainUpperRightDiv'>
                  <div className='MainProfileDiv'>
                    <div>
                      <div className='userAvtarDiv'>
                        {nameLetters}
                      </div>
                    </div>
                    <div className='userDetailDiv'>
                      <div
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          color: '#fff'
                        }}
                      >
                        {firstName} {lastName}
                      </div>
                      <div>
                        <div style={{ fontSize: 12,fontWeight: 600,color: '#fff' }}>{email}</div>
                      </div>
                    </div>
                </div>
                  </div>
                  <div>
                  <Tooltip title="Log Out">
                    <img onClick={()=>LogOutFun()} style={{width:30,cursor:'pointer'}} src={LogOutIcon} alt="" />
                    </Tooltip>
                  </div>
                  </div>
                </div>

                <div className='taskListDiv' >
                <div style={{ height: '85px' }}></div>
                <div
                    style={{
                      ...styles.menuItems,
                      paddingTop: 4,
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      padding:10
                    }}
                  >
                    <input
                      style={{...styles.searchBarInput, outline:"none"}}
                      className='searchBarInputCss'
                      type='text'
                      name='name'
                      placeholder='Search email or name....' autoComplete='off' onChange={handleChangeShareInput}
                    />
                    <img style={styles.searchBarIcon} src={Magnifier} />
                  </div>
                {allUserFilter && allUserFilter.length > 0 && allUserFilter.map((item: any, i: any) => (
                <div className='taskList'>
                  {/* <div>
                  {item.id}
                  </div> */}
                  <div>
                  <span style={{fontWeight:600,color:'#000'}}>{item.firstName} {item.lastName}</span> <br />
                 <span>{item.email}</span>
                  </div>
<div className='usersLinkBtnMainDiv'>
  <div className='usersLinkBtn' onClick={()=>verifyRegisterLink(item)}>Resend welcome email</div>
  <div className='usersLinkBtn' onClick={()=>resendPasswordLink(item)}>Send password reset email</div>
  {item && item.status && item.status != 1 ?
  <div className='usersLinkBtn' onClick={()=>handleDeleteConfirm(item)}>Delete account</div>
  :
  <div className='usersLinkDeactivatedAccountBtn'>Account deleted</div>
}
</div>
                </div>
                ))}
                </div>
            </div>
            
    </div>
  )
}

export default LandingPage
