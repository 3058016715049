import React, { useState } from 'react'
import styles from './AuthStyles'
import './Auth.css'
import MainLogo from '../../assets/mainLogo1.png'
import SignUp from './SignUp'
import ForgotPassword from './ForgotPassword';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL, post } from '../Services/Calls';
import { Base64 } from 'js-base64';
import AuthLoader from '../../assets/authLoader.png' 
import NickNameIcon from '../../assets/nickName.png' 
import NickNameActiveIcon from '../../assets/nickNameActive.png' 
import Padlock from '../../assets/padlock.png' 
import PadlockActive from '../../assets/padlockActive.png' 
import Cookies from '../Cookies/Cookies';


function Login () {
  const navigate = useNavigate();
  const [SignUpShow,setSignUpShow]= useState(false);
  const [forgotPasswordShow,setForgotPasswordShow]= useState(false);
  const [emailMsg, setEmailMsg] = useState<string>("");
  const [passwordMsg, setPasswordMsg] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [loader, setLoader] = useState(false);
  const [alreadyExist, setAlreadyExist] = useState(false);
  const [showAlreadyLine, setShowAlreadyLine] = useState(false);
  const [emailInputFocus, setEmailInputFocus] = useState(false);
  const [passwordInputFocus, setPasswordInputFocus] = useState(false);

  const handleLoginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    const form_data:any = e.currentTarget.elements;
  
    try {

      if (form_data[0].value == "") {
        setEmailMsg("Can't be empty");
      }
      if (form_data[0].value != "") {
        let pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        if (!pattern.test(form_data[0].value)) {
        setEmailMsg("Invalid input");
        return;
        }
      }
      if (form_data[1].value == "") {
        setPasswordMsg("Password can't be empty");
      }
      if (form_data[1].value != "") {
        if(form_data[1].value.length < 6){
        setPasswordMsg("Password requires 6~64 characters long.");
        return;
      }
    }
      if (
        form_data[0].value == "" ||
        form_data[1].value == "" 
      ) {
        return;
      }
      let tempEmail=form_data[0].value
      tempEmail=tempEmail.toLowerCase();
      const data = {
        email: Base64.encode(tempEmail),
        password: Base64.encode(form_data[1].value),
      };
      console.log("data123456", data);
      setLoader(true)
      const response = await post(BASE_URL + '/loginadmin', data)
      console.log('response23',response)
      if(response.data.status == 'success'){
        setLoader(false)
        if(response.data.data && response.data.data.length > 0){
        localStorage.setItem("user", JSON.stringify(response.data.data[0]));
        }
        if(response.data.accessToken){
        localStorage.setItem("token", response.data.accessToken);
      }
      //navigate('/webapp')
      localStorage.setItem("setCookie", "0");
         window.location.reload(); 
        //navigate('/webapp')
      }
      else{
        setLoader(false)
        setAlreadyExist(true)
        setShowAlreadyLine(true)
        // setErrorMsg('your email/password is incorrect. Please try again.')
      }

    } catch (ex) {
      setLoader(false)
      console.log('response',ex)
    }
  };




  return (
                     <div className='container-main'>
                       {/* {localStorage.getItem("setCookie") == null && <Cookies />} */}


      <div>
                     <h2 style={{textAlign:'center',fontWeight:'bold',marginBottom:36}}>Admin ManageStuff</h2>
       <div className='auth-container-box'>
        <form onSubmit={handleLoginSubmit}>

        <div className={emailInputFocus?'inputContainerDivActive':'inputContainerDiv'}>
           {emailInputFocus?
<img style={{width:20}} src={NickNameActiveIcon} alt="" />
:
<img style={{width:20}} src={NickNameIcon} alt="" />
}
<div style={{width:'100%'}}>
<input className='inputFeild' type="text" name="email" placeholder='Email' onChange={()=>{setAlreadyExist(false);setShowAlreadyLine(false)}}  onFocus={() => {setEmailMsg("");setErrorMsg("");setEmailInputFocus(true);setShowAlreadyLine(false)}} onBlur={(e)=>{setEmailInputFocus(false);
if (e.target.value != "") {
  let pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  if (!pattern.test(e.target.value)) {
  setEmailMsg("Invalid input");
  }
}
}} />
</div>
</div>
           <div className='errorMsgLine' style={{minHeight:30}}>
           {showAlreadyLine && <>
             {alreadyExist && 
             <span style={styles.inputErrorMsg}>Incorrect username or password</span>
                         }
                          </>}
            <span style={styles.inputErrorMsg}>{emailMsg}</span>
             </div>

             <div className={passwordInputFocus?'inputContainerDivActive':'inputContainerDiv'}>
           {passwordInputFocus?
<img style={{width:20}} src={PadlockActive} alt="" />
:
<img style={{width:20}} src={Padlock} alt="" />
}
<div style={{width:'100%'}}>
<input className='inputFeild' type="password" name="password" placeholder="Password"  maxLength={64} onFocus={() => {setPasswordMsg("");setErrorMsg("");setPasswordInputFocus(true);setShowAlreadyLine(true)}} onBlur={(e)=>{setPasswordInputFocus(false);
  if (e.target.value != "") {
        if(e.target.value.length < 6){
        setPasswordMsg("Password requires 6~64 characters long.");
      }}
}}/>
</div>
           </div>
           <div className='errorMsgLine' style={{minHeight:30}}><span style={styles.inputErrorMsg}>{passwordMsg}</span></div>

            {loader ?
    <button className='authBtnLoader'>
    Sign In...
    </button>
  :
        <button className='authBtn' type="submit">
        Sign In
        </button>
}
</form>

{/* <div className='makeLink' style={{fontSize:13}}  onClick={() => navigate("/login/requestRestPassword")} >Forgot Password</div>

<div className='makeLink' style={{textAlign:'center',marginTop:20}} onClick={() => navigate("/signup")}>
Sign Up for Free
</div> */}
       </div>
       </div>
       </div>
  )
}

export default Login
