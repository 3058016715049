import { COLOR } from '../Services/constants/Colors'
import BackGroungBlack from '../../assets/BackGroungBlack1.jpeg'

const styles = {
  mainContainer: {
    height: '100vh',
    width: '100vw',
    background: COLOR.PageBackGroundColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  innerContainer: {
    color: COLOR.HeadingColor,
    minHeight: 500,
    width: 550,
    background: 'white',
    borderRadius: 15,
    padding: 10,
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop:40,
    paddingBottom:40,
  },
  Button: {
    paddingLeft:40,
    paddingRight:40,
    paddingTop:6,
    paddingBottom:6,
    background:COLOR.ButtonBackGroundColor,
    color:COLOR.ButtonTextColor,
    borderRadius:5,
    marginTop:12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize:16,
    fontWeight:'600',
    boxShadow:
    '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    cursor:'pointer',
    width: '100%',
    border: 'none'
  },
  LogInInput: {
    padding:3,
    paddingLeft:10,
    paddingRight:10,
      borderRadius:5,
    width:'100%',
    marginTop:18,
    fontSize:14
  },
  inputErrorMsg: {
    fontSize:13,
    color:'#ff3180',
  },
  

}

export default styles
