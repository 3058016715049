import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, redirect, Navigate } from "react-router-dom";
import Login from './Components/Auth/Login';
import Testing from './Components/Testing';
import LandingPage from './Components/LandingPage/Landing';
import { ErrorBoundary } from "react-error-boundary";
import errorPageCrossIcon from '../src/assets/errorPageCrossIcon.png';
import { BASE_URL, post } from './Components/Services/Calls';
import ResetPassword from './Components/Auth/ResetPassword';
import MainPage from './Components/Home/MainPage';
import SignUp from './Components/Auth/SignUp';
import ForgotPassword from './Components/Auth/ForgotPassword';
import Verification from './Components/Auth/Verification';


function App() {



  useEffect(() => {
   
  }, []);



function fallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert" style={{padding:50,display:'flex',justifyContent:'center',height:'100vh',flexDirection:'column'}}>
      <div className='errorPageCrossIcon' style={{position: 'absolute',top:40,right:40,fontSize:20,cursor:'pointer'}} onClick={()=>window.location.reload()}>
       <img style={{width:15,height:15}} src={errorPageCrossIcon} alt="" />
      </div>
      <div style={{textAlign:'center'}}>
        <h3>We have been notified of the issue and are looking at it.</h3>
      </div>
        <div style={{textAlign:'center',marginTop:20,fontSize:20}}>
          <span style={{color:'var(--bs-link-color)',textDecoration: 'underline',cursor:'pointer'}} onClick={()=>window.location.reload()}>
            Click Here
          </span> &nbsp;
          <span>
            to reload page.
          </span>
        </div>
    </div>
  );
}

const logError = (error: Error, info: { componentStack: string }) => {
  // Do something with the error, e.g. log to an external API
  console.log('error: Error',error)
  console.log('info',info.componentStack)
  handleErrorCall(error,info.componentStack)
};

const handleErrorCall = async (error,info) => {
  let user = JSON.parse(localStorage.getItem('user') || '{}')

  try {
    const data = {
      title: error.message,
      desc: info,
      errorStackTrace: info,
      userid: user && user.id?user.id:"0",
    };
    console.log("data123456", data);
    const response = await post(BASE_URL + '/erroruser', data)
    console.log('response23',response)
    if(response.data.status == 'success'){
    }
    else{
    }

  } catch (ex) {
    console.log('response',ex)
  }
};


  const checkAuthLanding = (component: any) => {
    if (!localStorage.getItem("user") || !localStorage.getItem("token")) {
      return <Navigate to="/"/>;
    } 
    return component;
  };
  const checkAuthLogin = (component: any) => {
    if (!localStorage.getItem("user") || !localStorage.getItem("token")) {
      return component;
     // return <Navigate to="/"/>;
    } 
    return <Navigate to="/webapp"/>;
  };

  return (
    <ErrorBoundary fallbackRender={fallbackRender} onError={logError}>
<BrowserRouter>
      <Routes>
      <Route path="/" element={checkAuthLogin(<Login />)} /> 
      <Route path="/webapp" element={checkAuthLanding(<LandingPage />)} /> 
      </Routes>
    </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
