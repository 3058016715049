export const COLOR = {
   HeadingColor:'#000',
   PageBackGroundColor:'#F5F5F8',
   FontColorWhite:'#fff',
   ButtonBackGroundColor:'#5E97C3',
   ButtonTextColor:'#fff',
   NavbarBackGroundColor:'#6478C7',
   WhiteColor:'#fff',
   MenuActive:'rgba(94, 151, 195, 0.6)',
   Red:'red',
  };